<template>
  <validation-provider
    v-slot="validationContext"
    ref="text"
    :rules="{ required: currentQuestionData.is_required }"
    vid="text"
    name="Answer"
  >
    <b-form-group class="p-1">
      <b-form-textarea
        id="text-answer"
        ref="textAnswer"
        v-model="answerText"
        class="shadow-sm"
        aria-describedby="question-error"
        maxlength="500"
        :state="
          getValidationState(
            validationContext
          )
        "
      />
      <b-form-invalid-feedback
        :state="
          getValidationState(
            validationContext
          )
        "
      >
        {{
          validationContext.errors[0]
        }}
      </b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import { BFormTextarea, BFormGroup, BFormInvalidFeedback } from "bootstrap-vue";
import { getValidationState } from "@/libs/utils";

export default {
  name: "SingleTextBox",
  components: {
    BFormTextarea,
    BFormGroup,
    ValidationProvider,
    BFormInvalidFeedback
  },

  props: {
    currentQuestionData: {
      type: Object,
      required: true,
    }
  },

  data() {
    return {
      answerId: null,
      answerText: null
    };
  },

  watch: {
    answerText() {
      var result = [];
      if (this.answerId) {
        result = [{question_id: this.currentQuestionData.id, answer_text: this.answerText, id: this.answerId }];
      } else {
        result = [{question_id: this.currentQuestionData.id, answer_text: this.answerText }];
      }

      this.$emit("input", result);
    },
    currentQuestionData: {
      handler() {
        if (this.currentQuestionData && this.currentQuestionData.answers && this.currentQuestionData.answers.length > 0) {
          this.answerText = this.currentQuestionData.answers[0].answer_text;
          this.answerId = this.currentQuestionData.answers[0].id;
        } else {
          this.answerText = null;
          this.answerId = null;
        }
      },
      deep: true,
      immediate: true
    },
  },
  setup() {
    return {
      getValidationState,
    };
  }
};
</script>
