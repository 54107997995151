<template>
  <b-form-group :class="componentClasses">
    <b-form-checkbox-group
      v-if="isMultiAnswer"
      v-model="selected"
      stacked
      :options="options"
      :disabled="disableInputs"
    />
    <b-form-radio-group
      v-if="!isMultiAnswer"
      v-model="selected"
      stacked
      :options="options"
      :disabled="disableInputs"
    />
    <b-form-invalid-feedback
      v-if="messageError"
      class="mt-1 d-block invalid-feedback"
      tag="span"
    >
      {{ messageError }}
    </b-form-invalid-feedback>
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormCheckboxGroup, BFormRadioGroup, BFormInvalidFeedback } from "bootstrap-vue";
import _isEqual from 'lodash/isEqual';

export default {
  name: "MultipleChoiceMatching",
  components: {
    BFormGroup,
    BFormCheckboxGroup,
    BFormRadioGroup,
    BFormInvalidFeedback
  },
  props: {
    currentQuestionData: {
      type: Object,
      required: true,
    },
    messageError: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: "edit",
    },
  },
  data() {
    return {
      selected: [],
      options: [],
      payload: [],
    };
  },
  computed: {
    componentClasses() {
      if (this.mode === "edit") {
        return "p-1";
      }
      return "";
    },
    disableInputs() {
      return this.mode === 'view';
    },
    answerChoices() {
      return this.currentQuestionData.choices.filter(
        choice => !choice.is_other
      );
    },
    isMultiAnswer() {
      return Number(this.currentQuestionData.choice_limit) >  Number(1);
    }
  },
  watch: {
    selected(n, o) {    
      if(_isEqual(n, o)) {
        return;
      }
      this.setOptionsDisabled();
      this.handlePayload();
    },
    payload(n) {
      if(n) {
        this.$emit("input", n);
      }
    },
    currentQuestionData: {
      handler(n, o) {
        if(_isEqual(n, o)) {
          return;
        }
        if(n) {
          this.setQuestionOptions();
          this.setOptionsDisabled();
        }
      },
      deep: true,
      immediate: true
    },
  },
  methods: {
    getTranslationTextByUserLocale(translations, propertyName) {
      return this.$store.getters["participants/getTranslationTextByUserLocale"](
        translations,
        propertyName
      );
    },
    handlePayload() {
      if(this.isMultiAnswer && this.selected.length > 0) {
        this.payload = this.selected.map(select => ({
          question_id: this.currentQuestionData.id,
          choice_id: select,
        }));
      } 
      if(!this.isMultiAnswer && this.selected) {
        this.payload = [{
          question_id: this.currentQuestionData.id,
          choice_id: this.selected,
        }];
      } 
      
    },
    setOptionsDisabled() {
      if(this.selected && this.selected.length === Number(this.currentQuestionData.choice_limit)) {
        this.options = this.answerChoices.map(o => {
          if(!this.selected.includes(o.id)) {
            return { text: this.getTranslationTextByUserLocale(
              o.translations,
              "choice_text"
            ), 
            value: o.id, 
            disabled: true 
            };
          } else {
            return { text: this.getTranslationTextByUserLocale(
              o.translations,
              "choice_text"
            ), 
            value: o.id, 
            disabled: false 
            };
          }
        });
      } else {
        this.options = this.answerChoices.map(o => ({ text: this.getTranslationTextByUserLocale(
          o.translations,
          "choice_text"
        ), value: o.id, disabled: false }));
      }
    },
    setQuestionOptions () {
      if(this.isMultiAnswer) {
        this.selected = this.currentQuestionData.answers.map(
          answer => answer.choice_id
        );
      } else {
        this.selected = this.currentQuestionData.answers.map(
          answer => answer.choice_id
        ).shift();
      }
    },
  },
};
</script>
