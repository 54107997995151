<template>
  <div v-if="isLoading">
    <aom-skeleton-loader />
  </div>
  <validation-observer
    v-else
    ref="observer"
    v-slot="{ invalid, handleSubmit }"
  >
    <section
      v-if="currentQuestion"
      class="application-step-section"
    >
      <div class="as-wrapper">
        <div class="as-header">
          <h2 class="text-center text-primary text-capitalize">
            Edit Survey
          </h2>
        </div>

        <div class="as-body">
          <div class="as-question-group">
            <div class="edit-header primary-color">
              Editing Survey Question
              {{ currentQuestion.question_order + 1 }}
            </div>

            <div class="as-question-box">
              <div class="as-question-number">
                Q{{ currentQuestion.question_order + 1 }}
              </div>
              <div
                v-if="currentQuestion"
                class="as-question-content"
              >
                <fieldset
                  v-if="
                    currentQuestion.type_id ===
                      modelQuestionTypes.MATCHING ||
                      currentQuestion.type_id === modelQuestionTypes.CHOICE ||
                      currentQuestion.type_id === modelQuestionTypes.SCALE
                  "
                >
                  <legend class="asqc-question primary-color">
                    {{
                      getTranslationTextByUserLocale(
                        currentQuestion.translations,
                        "question_text"
                      )
                    }}
                  </legend>

                  <div class="asqc-answer-box">
                    <!-- Multiple Choice Match -->
                    <multiple-choice-matching
                      v-if="
                        currentQuestion.type_id ===
                          modelQuestionTypes.MATCHING
                      "
                      :current-question-data="currentQuestion"
                      :message-error="messageError"
                      @input="handleInput"
                    />

                    <!-- Multiple Choice -->
                    <multiple-choice
                      v-if="
                        currentQuestion.type_id ===
                          modelQuestionTypes.CHOICE
                      "
                      :current-question-data="currentQuestion"
                      :message-error="messageError"
                      :message-error-other="messageErrorOther"
                      @input="handleInput"
                    />

                    <!-- Rating Scale -->
                    <rating-scale
                      v-if="
                        currentQuestion.type_id === modelQuestionTypes.SCALE
                      "
                      :current-question-data="currentQuestion"
                      :message-error="messageError"
                      @input="handleInput"
                    />
                  </div>
                </fieldset>
                <label
                  v-else
                  class="asqc-question primary-color"
                  for="text-answer"
                >
                  {{
                    getTranslationTextByUserLocale(
                      currentQuestion.translations,
                      "question_text"
                    )
                  }}
                </label>

                <div class="asqc-answer-box">
                  <!-- Single Text Box -->
                  <single-text-box
                    v-if="
                      currentQuestion.type_id === modelQuestionTypes.TEXT ||
                        currentQuestion.type_id ===
                        modelQuestionTypes.STATEMENT
                    "
                    :current-question-data="currentQuestion"
                    :message-error="messageError"
                    @input="handleInput"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="as-footer align-left-with-question">
          <b-row>
            <div class="col-md-3 mb-3 col-left">
              <b-button
                block
                variant="outline-secondary"
                @click="handleCancelForm"
              >
                Cancel
              </b-button>
            </div>
            <div class="col-md-3 offset-md-6 mb-3 col-right">
              <b-button
                block
                variant="primary"
                :disabled="invalid"
                @click="handleSubmit(saveAnswer)"
              >
                <b-spinner
                  v-if="isSubmitting"
                  small
                />
                Save
              </b-button>
            </div>
          </b-row>
        </div>
      </div>
    </section>
  </validation-observer>
</template>

<script>
import { mapGetters } from "vuex";
import { BRow, BSpinner, BButton } from "bootstrap-vue";
import SingleTextBox from "@/views/components/partials/SingleTextBox.vue";
import MultipleChoice from "@/views/components/partials/MultipleChoice";
import MultipleChoiceMatching from "@/views/components/partials/MultipleChoiceMatching";
import RatingScale from "@/views/components/partials/RatingScale";
import { questionTypes as modelQuestionTypes } from "@/models/questionTypes";
import { ValidationObserver } from "vee-validate";
import { makeSuccessToast, makeErrorToast } from "@/libs/utils";
import AomSkeletonLoader from "@aom-core/AomSkeletonLoader.vue";

export default {
  name: "SurveyEdit",

  components: {
    BSpinner,
    SingleTextBox,
    MultipleChoice,
    MultipleChoiceMatching,
    RatingScale,
    BButton,
    BRow,
    ValidationObserver,
    AomSkeletonLoader
  },
  props: {
    token: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      header: null,
      role: null,
      messageError: null,
      messageErrorOther: null,
      newAnswers: [],
      modelQuestionTypes: modelQuestionTypes,
      isLoading: false,
      isSubmitting: false
    };
  },

  computed: {
    ...mapGetters("app", {
      appCurrentProgram: "currentProgram",
    }),
    ...mapGetters("profile", ["isParticipantPortal", "profile"]),
    ...mapGetters("participants", ["surveyAnswerData"]),

    currentQuestion() {
      if (!this.surveyAnswerData) {
        return null;
      }
      const questionId = this.$route.params.questionId;
      if (!questionId) {
        return null;
      }
      return this.surveyAnswerData.questions.find(
        question => question.id === Number(questionId)
      );
    }
  },

  async created() {
    if (!this.surveyAnswerData) {
      try {
        this.isLoading = true;

        if (
          this.isParticipantPortal &&
          this.appCurrentProgram &&
          this.appCurrentProgram.id
        ) {
          await this.$store.dispatch("participants/FETCH_OWN_SURVEY_ANSWERS", {
            programId: this.appCurrentProgram.id,
            formId: this.$route.params.form,
            userId: this.profile.id
          });
        }
      } catch (e) {
        this.$log.error(e);
        this.$toast(makeErrorToast("Survey not loaded."));
      } finally {
        this.isLoading = false;
      }
    }
  },

  methods: {
    getTranslationTextByUserLocale(translations, propertyName) {
      return this.$store.getters["participants/getTranslationTextByUserLocale"](
        translations,
        propertyName
      );
    },

    handleInput(input) {
      this.newAnswers = input;
    },

    makeRequestParams() {
      const params = {};
      if (this.appCurrentProgram && this.appCurrentProgram.id) {
        params.programId = this.appCurrentProgram.id;
      }
      params.formId = this.$route.params.form;
      params.userId = this.profile.id;
      params.token = this.$route.params.token;

      return params;
    },

    makeRequestData() {
      const data = { answers: this.newAnswers };
      return data;
    },

    async saveAnswer() {
      try {
        this.isSubmitting = true;

        const params = this.makeRequestParams();
        const data = this.makeRequestData();
        const isSuccess = this.token? await this.$store.dispatch(
          "participants/SAVE_OWN_SURVEY_ANSWERS_BY_TOKEN",
          {
            params,
            data,
          }
        ): await this.$store.dispatch(
          "participants/SAVE_OWN_SURVEY_ANSWERS",
          {
            params,
            data,
          }
        );

        if (isSuccess) {
          this.$toast(makeSuccessToast("Saved answer successfully."));

          this.$router.push({
            name: this.token? 'participant-survey-public-overview': 'participant-survey-overview',
          });
        }
      } catch (e) {
        this.$toast(makeErrorToast("Something went wrong. Answer not saved."));
        this.$log.error(e);
      } finally {
        this.isSubmitting = false;
      }
    },

    handleCancelForm() {
      if (this.token) {
        this.$router.push({
          name: 'participant-survey-public-overview',
        });
      } else {
        this.$router.push({
          name: 'participant-survey-overview',
        });
      }
    },
  },
};
</script>
