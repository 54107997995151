var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isLoading)?_c('div',[_c('aom-skeleton-loader')],1):_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [(_vm.currentQuestion)?_c('section',{staticClass:"application-step-section"},[_c('div',{staticClass:"as-wrapper"},[_c('div',{staticClass:"as-header"},[_c('h2',{staticClass:"text-center text-primary text-capitalize"},[_vm._v(" Edit Survey ")])]),_c('div',{staticClass:"as-body"},[_c('div',{staticClass:"as-question-group"},[_c('div',{staticClass:"edit-header primary-color"},[_vm._v(" Editing Survey Question "+_vm._s(_vm.currentQuestion.question_order + 1)+" ")]),_c('div',{staticClass:"as-question-box"},[_c('div',{staticClass:"as-question-number"},[_vm._v(" Q"+_vm._s(_vm.currentQuestion.question_order + 1)+" ")]),(_vm.currentQuestion)?_c('div',{staticClass:"as-question-content"},[(
                  _vm.currentQuestion.type_id ===
                    _vm.modelQuestionTypes.MATCHING ||
                    _vm.currentQuestion.type_id === _vm.modelQuestionTypes.CHOICE ||
                    _vm.currentQuestion.type_id === _vm.modelQuestionTypes.SCALE
                )?_c('fieldset',[_c('legend',{staticClass:"asqc-question primary-color"},[_vm._v(" "+_vm._s(_vm.getTranslationTextByUserLocale( _vm.currentQuestion.translations, "question_text" ))+" ")]),_c('div',{staticClass:"asqc-answer-box"},[(
                      _vm.currentQuestion.type_id ===
                        _vm.modelQuestionTypes.MATCHING
                    )?_c('multiple-choice-matching',{attrs:{"current-question-data":_vm.currentQuestion,"message-error":_vm.messageError},on:{"input":_vm.handleInput}}):_vm._e(),(
                      _vm.currentQuestion.type_id ===
                        _vm.modelQuestionTypes.CHOICE
                    )?_c('multiple-choice',{attrs:{"current-question-data":_vm.currentQuestion,"message-error":_vm.messageError,"message-error-other":_vm.messageErrorOther},on:{"input":_vm.handleInput}}):_vm._e(),(
                      _vm.currentQuestion.type_id === _vm.modelQuestionTypes.SCALE
                    )?_c('rating-scale',{attrs:{"current-question-data":_vm.currentQuestion,"message-error":_vm.messageError},on:{"input":_vm.handleInput}}):_vm._e()],1)]):_c('label',{staticClass:"asqc-question primary-color",attrs:{"for":"text-answer"}},[_vm._v(" "+_vm._s(_vm.getTranslationTextByUserLocale( _vm.currentQuestion.translations, "question_text" ))+" ")]),_c('div',{staticClass:"asqc-answer-box"},[(
                    _vm.currentQuestion.type_id === _vm.modelQuestionTypes.TEXT ||
                      _vm.currentQuestion.type_id ===
                      _vm.modelQuestionTypes.STATEMENT
                  )?_c('single-text-box',{attrs:{"current-question-data":_vm.currentQuestion,"message-error":_vm.messageError},on:{"input":_vm.handleInput}}):_vm._e()],1)]):_vm._e()])])]),_c('div',{staticClass:"as-footer align-left-with-question"},[_c('b-row',[_c('div',{staticClass:"col-md-3 mb-3 col-left"},[_c('b-button',{attrs:{"block":"","variant":"outline-secondary"},on:{"click":_vm.handleCancelForm}},[_vm._v(" Cancel ")])],1),_c('div',{staticClass:"col-md-3 offset-md-6 mb-3 col-right"},[_c('b-button',{attrs:{"block":"","variant":"primary","disabled":invalid},on:{"click":function($event){return handleSubmit(_vm.saveAnswer)}}},[(_vm.isSubmitting)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Save ")],1)],1)])],1)])]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }