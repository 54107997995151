<template>
  <div class="rating-scale-answer-section">
    <div class="table-responsive">
      <table class="table table-multichoise">
        <thead>
          <tr>
            <th />
            <th
              v-for="(qCol, thIndex) in currentQuestionData.choices"
              :key="thIndex"
              class="text-left rating-scale-answer-row-content primary-color"
            >
              {{
                getTranslationTextByUserLocale(qCol.translations, "choice_text")
              }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(qRow, qIndex) in currentQuestionData.statements"
            :key="qIndex"
          >
            <td class="rating-scale-answer-row-content primary-color">
              {{
                getTranslationTextByUserLocale(
                  qRow.translations,
                  "question_text"
                )
              }}
            </td>
          
            <td
              v-for="(aCol, aIndex) in currentQuestionData.choices"
              :key="aIndex"
              class="text-center choices"
            >
              <b-form-radio
                v-model="selected[qIndex]"
                :aria-label="
                  getTranslationTextByUserLocale(
                    qRow.translations,
                    'question_text'
                  ) +
                    ' - ' +
                    getTranslationTextByUserLocale(
                      aCol.translations,
                      'choice_text'
                    )
                "
                :value="{choice_id: aCol.id, question_id: qRow.id, statement_index: qIndex, current_question_id: currentQuestionData.id}"
                :checked="aCol.id === selected[qIndex].choice_id"
                tabindex="0"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <b-form-invalid-feedback
        v-if="messageError"
        class="ml-1 d-inline invalid-feedback"
        tag="span"
      >
        Please make a selection for each row.
      </b-form-invalid-feedback>
    </div>
  </div>
</template>

<script>
import { BFormRadio, BFormInvalidFeedback } from "bootstrap-vue";
import _isEqual from 'lodash/isEqual';
import { getValidationState } from "@/libs/utils";
import { validate } from 'vee-validate';

export default {
  name: "RatingScale",
  components: {
    BFormRadio,
    BFormInvalidFeedback,
  },
  directives: { validate  },
  props: {
    currentQuestionData: {
      type: Object,
      required: true,
    },
    messageError: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      selected: [],
      payload: [],
      hasMessageError: false
    };
  },
  computed: {
    validation() {
      return this.selected.length === 0;
    },
    answerChoices() {
      return this.currentQuestionData.choices.filter(
        choice => !choice.is_other
      );
    }
  },
  watch: {
    selected: {
      handler() {
        this.handlePayload();
      },
      deep: true,
      immediate: true,
    },
    payload(n) {
      if(n && n.length > 0) {
        this.$emit("input", {payload: this.payload, selected: this.selected});
      }
    },
    currentQuestionData: {
      handler(n, o) {
        if(_isEqual(n, o)) {
          return;
        }
        const answers = this.currentQuestionData.statements.map((statement, index) => {
          if (statement.answers && statement.answers.length > 0) {
            return {
              'choice_id': statement.answers[0].choice_id,
              'question_id': statement.answers[0].question_id,
              'statement_index': index,
              'current_question_id': this.currentQuestionData.id
            };
          } else {
            return {
              'choice_id': undefined,
              'question_id': undefined,
              'statement_index': index,
              'current_question_id': this.currentQuestionData.id
            };
          }
        });
        this.selected = answers;
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    getTranslationTextByUserLocale(translations, propertyName) {
      return this.$store.getters["participants/getTranslationTextByUserLocale"](
        translations,
        propertyName
      );
    },

    handlePayload() {
      this.payload = this.selected.filter(choice => choice.choice_id && choice.question_id);
      if(this.payload.length > 0) {
        this.$emit("input", this.payload);
      }
    },
  },
  setup() {
    return {
      getValidationState
    };
  },
};
</script>
<style lang="scss" scoped>
.flex-align-td {
  display: flex;
  align-items: center;
  align-content: center;
}
.rating-scale-answer-row-content {
  font-size: 1em;
  text-align: left;
}
.invalid-feedback {
  display: inline !important;
}
.rating-scale-answer-section .table thead th {
  font-size: 1em;
  text-transform: none;
}
</style>