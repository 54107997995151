var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rating-scale-answer-section"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-multichoise"},[_c('thead',[_c('tr',[_c('th'),_vm._l((_vm.currentQuestionData.choices),function(qCol,thIndex){return _c('th',{key:thIndex,staticClass:"text-left rating-scale-answer-row-content primary-color"},[_vm._v(" "+_vm._s(_vm.getTranslationTextByUserLocale(qCol.translations, "choice_text"))+" ")])})],2)]),_c('tbody',_vm._l((_vm.currentQuestionData.statements),function(qRow,qIndex){return _c('tr',{key:qIndex},[_c('td',{staticClass:"rating-scale-answer-row-content primary-color"},[_vm._v(" "+_vm._s(_vm.getTranslationTextByUserLocale( qRow.translations, "question_text" ))+" ")]),_vm._l((_vm.currentQuestionData.choices),function(aCol,aIndex){return _c('td',{key:aIndex,staticClass:"text-center choices"},[_c('b-form-radio',{attrs:{"aria-label":_vm.getTranslationTextByUserLocale(
                  qRow.translations,
                  'question_text'
                ) +
                  ' - ' +
                  _vm.getTranslationTextByUserLocale(
                    aCol.translations,
                    'choice_text'
                  ),"value":{choice_id: aCol.id, question_id: qRow.id, statement_index: qIndex, current_question_id: _vm.currentQuestionData.id},"checked":aCol.id === _vm.selected[qIndex].choice_id,"tabindex":"0"},model:{value:(_vm.selected[qIndex]),callback:function ($$v) {_vm.$set(_vm.selected, qIndex, $$v)},expression:"selected[qIndex]"}})],1)})],2)}),0)]),(_vm.messageError)?_c('b-form-invalid-feedback',{staticClass:"ml-1 d-inline invalid-feedback",attrs:{"tag":"span"}},[_vm._v(" Please make a selection for each row. ")]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }