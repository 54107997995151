<template>
  <b-form-group
    v-if="currentQuestionData"
    :class="componentClasses"
  >
    <b-form-checkbox-group
      v-if="isMultiAnswer"
      v-model="selected"
      stacked
      :options="options"
      :disabled="disableInputs"
      tabindex="0"
    />
    <b-form-radio-group
      v-if="!isMultiAnswer"
      v-model="selected"
      stacked
      :options="options"
      :disabled="disableInputs"
      tabindex="0"
    />
    <div
      v-if="isChoiceOtherSelected"
      class="p-0 mt-3 mb-1"
    >
      <b-alert
        v-if="messageErrorOther"
        id="other-question-error"
        class="message-error mb-2 px-2 py-1"
        variant="danger"
        :show="messageErrorOther"
      >
        {{ messageErrorOther }}
      </b-alert>
      <b-form-textarea
        ref="otherAnswer"
        v-model="answerTextOther"
        :placeholder="otherPlaceHolderText"
        tabindex="0"
        :disabled="disableInputs"
        aria-describedby="other-question-error"
        :aria-invalid="messageErrorOther ? 'true' : 'false'"
        aria-label="Other Answer"
        class="form-control"
        maxlength="500"
        @change="onchangeAnswerOther()"
      />
    </div>
    <b-form-invalid-feedback
      v-if="messageError"
      class="mt-1 d-block invalid-feedback"
      tag="span"
    >
      {{ errorMessage }}
    </b-form-invalid-feedback>
    <b-form-invalid-feedback
      v-if="messageErrorOther"
      class="mt-1 d-block invalid-feedback"
      tag="span"
    >
      {{ messageErrorOther }}
    </b-form-invalid-feedback>
  </b-form-group>
</template>

<script>
import {
  BFormCheckboxGroup,
  BFormGroup,
  BFormTextarea,
  BAlert,
  BFormRadioGroup,
  BFormInvalidFeedback
} from "bootstrap-vue";
import _isEqual from 'lodash/isEqual';

export default {
  name: "MultipleChoice",
  components: {
    BFormGroup,
    BFormTextarea,
    BAlert,
    BFormCheckboxGroup,
    BFormRadioGroup,
    BFormInvalidFeedback
  },

  props: {
    currentQuestionData: {
      type: Object,
      required: true,
    },

    messageError: {
      type: String,
      default: "",
    },

    messageErrorOther: {
      type: String,
      default: "",
    },

    mode: {
      type: String,
      default: "edit",
    },
  },
  data() {
    return {
      answers: [...this.currentQuestionData.answers],
      is_locked: false,
      answerTextOther: "",
      selected: undefined,
      options: [],
      payload: [],
      otherPlaceHolderText: ''
    };
  },
  computed: {
    componentClasses() {
      if (this.mode === "edit") {
        return "p-1 mb-1";
      }
      return "mb-1";
    },
    disableInputs() {
      return this.mode === 'view';
    },
    answerChoices() {
      return this.currentQuestionData.choices;
    },
    isMultiAnswer() {
      return Number(this.currentQuestionData.choice_limit) >  Number(1);
    },
    choiceOther() {
      return this.currentQuestionData.choices.find(choice => choice.is_other);
    },
    isChoiceOtherSelected() {
      if(this.isMultiAnswer && this.choiceOther) {
        return this.selected.includes(this.choiceOther.id);
      } 
      if(!this.isMultiAnswer && this.choiceOther) {
        return this.selected === this.choiceOther.id;
      }
      return false;
    },
    errorMessage() {
      return this.currentQuestionData.choice_limit > 1 ? `Please select up to ${this.currentQuestionData.choice_limit} answers.` : `Please select 1 answer`;
    }
  },

  watch: {
    selected(n, o) {
      if(_isEqual(n, o)) {
        return;
      }
      this.setOptionsDisabled();
      this.handlePayload();
    },
    payload() {
      this.$emit("input", this.payload);
    },
    currentQuestionData: {

      handler(n, o) {
        if(_isEqual(n, o)) {
          return;
        }
        if(n) {
          this.setQuestionOptions();
          this.setOptionsDisabled();
         
          if(this.choiceOther) {
            const otherAnswer = this.currentQuestionData.answers.find(
              answer => answer.choice_id === this.choiceOther.id
            );
            if (otherAnswer) {
              this.answerTextOther = otherAnswer.answer_text;
            } else {
              if(this.choiceOther) {
                this.otherPlaceHolderText = this.getTranslationTextByUserLocale(
                  this.choiceOther.translations,
                  "choice_text"
                );
              }
            }
          }
        }
      },
      deep: true,
      immediate: true
    },
  },

  methods: {
    setOptionsDisabled () {
      if(this.selected && this.selected.length === Number(this.currentQuestionData.choice_limit)) {
        this.options = this.answerChoices.map(o => {
          if(!this.selected.includes(o.id)) {
            return { text: this.getTranslationTextByUserLocale(
              o.translations,
              "choice_text"
            ), 
            value: o.id, 
            disabled: true 
            };
          } else {
            return { text: this.getTranslationTextByUserLocale(
              o.translations,
              "choice_text"
            ), 
            value: o.id, 
            disabled: false 
            };
          }
        });
      } else {
        this.options = this.answerChoices.map(o => ({ text: this.getTranslationTextByUserLocale(
          o.translations,
          "choice_text"
        ), value: o.id, disabled: false }));
      }
    },
    getTranslationTextByUserLocale(translations, propertyName) {
      return this.$store.getters["participants/getTranslationTextByUserLocale"](
        translations,
        propertyName
      );
    },
    setQuestionOptions () {
      if(this.isMultiAnswer) {
        this.selected = this.currentQuestionData.answers.map(
          answer => answer.choice_id
        );
      } else {
        this.selected = this.currentQuestionData.answers.map(
          answer => answer.choice_id
        ).shift();
      }
    },
    handlePayload() {
      if(this.isMultiAnswer) {
        this.payload = this.selected.map(select =>
          this.choiceOther && select === this.choiceOther.id
            ? {
              question_id: this.currentQuestionData.id,
              choice_id: select,
              answer_text: this.answerTextOther,
            }
            : {
              question_id: this.currentQuestionData.id,
              choice_id: select,
            }
        );
      } else {
        // Single Answer
        this.payload = [
          this.choiceOther && this.selected === this.choiceOther.id
            ? {
              question_id: this.currentQuestionData.id,
              choice_id: this.selected,
              answer_text: this.answerTextOther
            }
            : {
              question_id: this.currentQuestionData.id,
              choice_id: this.selected
            }];
      }
      
    },

    onchangeAnswerOther() {
      this.payload = this.payload.map(answer =>
        this.choiceOther && answer.choice_id === this.choiceOther.id
          ? { ...answer, answer_text: this.answerTextOther }
          : answer
      );      
    },
  },
};
</script>
